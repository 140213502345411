#tour-info table{
  width: 100%;
}
#tour-info table td{
  min-width: 14vw;
  padding: .8rem 0;
  font-size: var(--font_para);
  vertical-align: top;
}

#tour-info table td:last-child{
  color: var(--green_color) !important;
}

#tour-info table tr:last-child td:last-child{
  color: rgb(189, 189, 189) !important;
}

#tour-info .manager_table td{
  background-color: var(--primary_color);
  padding: .4rem 1rem;
  vertical-align: middle;
}

#tour-info .manager_table td.status{
  color: var(--green_color) !important;
}

#tour-info .manager_table td button{
  background-color: var(--primary-fade_color);
  color: white;
}

/* Add manager Form Css  */
#add_manager_form{
  height: 35vw;
  width: 30vw;
  padding: 2rem;
}

#add_manager_form h2{
  font-size: 1.3rem;
  font-family: Lato-raguler;
  letter-spacing: .05rem;
}

#add_manager_form .filler_elem img:last-child{
   left: -14%;
   bottom: -15%;
}

#add_manager_form .filler_elem img:first-child{
  right: -14%;
  top: -15%;
}

#add_manager_form .add_manager_cont{
  width: 72%;
}

#add_manager_form .add_manager_cont{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   margin-top: 1.5rem;
}

#add_manager_form .add_manager_cont .search_bar{
  display: flex;
  align-items: center;
  background-color: var(--secondary_color);
  padding: .5rem .7rem;
  width: 100%;
  justify-content: space-between;
}

.add_manager_cont .search_bar input{
  background-color: transparent;
  border: none;
  outline: none;
  width: 85%;
  font-size: .9rem;
}

#add_manager_form .add_manager_cont .search_bar i{
  color: var(--green_color);
  border-left: .1rem solid var(--green_color);
  padding: .1rem 0;
  padding-left: .7rem;
}

#add_manager_form .list_of_users{
  height: 17vw;
  overflow-y: auto;
  margin-top: 1.4rem;
  padding-right: .2rem;
}

#add_manager_form .list_of_users::-webkit-scrollbar{
  width: .2rem;
}

#add_manager_form .list_of_users::-webkit-scrollbar-thumb{
  background-color: var(--secondary_color);
}

#add_manager_form .list_of_users .user{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.4rem;
}

#add_manager_form .list_of_users .user:first-child{
  margin-top: initial;
}

#add_manager_form .player .player-info h4{
   font-size: var(--font_para);
}

#add_manager_form .player .profile-img{
  height: 1.9rem;
  width: 1.9rem;
}

#add_manager_form .add_manager_btn{
  padding: .1rem 1rem;
  font-size: .8rem;
  border-radius: initial;
}

#add_manager_form .btn_cont{
  margin-top: 2rem;
  justify-content: space-between;
  width: 100%;
  padding: initial;
}

#add_manager_form .btn_cont button{
  width: 48%;
  margin: initial;
}