#tournament_leader_board table{
    border-collapse:separate;
    border-spacing:0 1px;
}

#tournament_leader_board table tbody tr td{
    border: none;
}

#tournament_leader_board  .hta-points{
    background-color: var(  --green_color);
    display: flex;
    justify-content: center;
    font-family: Lato-bold;
}