.player{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.player .profile-img{
    height: 1.4rem;
    width: 1.4rem;
    background-color: var(--green_color);
    border-radius: 100%;
    margin-right: .5rem;
    overflow: hidden;
}

.player .profile-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.player .player-info h4{
    margin: initial;
    font-size: var(--font_small);
}

.player .player-info p{
    margin: initial;
    color: var(--gray_color-dark);
    font-style: italic;
}