.drag_and_drop_cont{
    width: 50%;
    margin: 0 auto;
    background-color: var(--primary_color);
    padding: 2rem;
}

.drag_and_drop_cont h2{
    text-align: center;
    margin-bottom: 2rem;
}
  
  .drag_and_drop_cont .drop-here{
    position: relative;
    /* border: .1rem dashed var(--green_color); */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%237E9D10FF' stroke-width='2' stroke-dasharray='3%2c 9' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: .2rem;
    /* border-radius: 1rem; */
  
  }
  
   .drag_and_drop_cont label {
    border-radius: .5rem;
    overflow: hidden;
    border: none;
    height: 17vw;
  }

  .drag_and_drop_cont label input{
    height: 100%;
    width: 100%;
    display: initial !important;
    position: relative;
    z-index: 1;
    opacity: 0 !important;
  }
  
  .drag_and_drop_cont label svg, .sc-ewnqHT{
    opacity: 0;
  }
  
  .drag_and_drop_cont .drop-here .txt_cont{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .drag_and_drop_cont .drop-here .txt_cont img{
    margin-bottom: 2rem;
    height: 4rem;
  }
  
  .drag_and_drop_cont .drop-here .txt_cont p{
    font-size: 1.1rem;
    font-family: Lato-raguler;
    margin-bottom: .3rem;
    color: rgb(78, 78, 78);
  }
  
  .drag_and_drop_cont .drop-here .txt_cont .browse{
    color: var(--green_color) !important;
    text-decoration: underline;
    text-transform: capitalize;
  }
  
  .drag_and_drop_cont .drop-here .txt_cont .file_type{
    font-size: .8rem !important;
    margin-top: .8rem;
    color: gray !important;
  }
  
  .drop-here .txt_cont .file_type span{
    margin-left: .4rem;
  }

  .drag_and_drop_cont button{
    height: 3rem;
    text-transform: uppercase;
    font-family: Lato-Medium;
  }