.loader_cont{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.628);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader_cont img{
    height: 8rem;
    object-fit: contain;
}