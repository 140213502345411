.side-bar{
    background-color: var(--secondary_color);
    height: fit-content;
    width: 100%;
}

.side-bar .nav-link_cont{
    display: flex;
    flex-direction: column;
    padding: 1.8rem;
}

.side-bar .nav-link_cont a:first-child{
    margin: initial;
}

.side-bar .nav-link_cont a{
    position: relative;
    padding: 1.1rem 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    transition: all .5s;
   font-size: var(--font_para);
   margin-top: 1.6rem;
   border-radius: 1rem;
   color: white;
   transition: all .5s;

}

.side-bar .nav-link_cont a:hover{
    background-color: var(--primary-fade_color);
}

.side-bar .nav-link_cont a.active{
  background-color: var(--primary-fade_color);
  font-family:  Lato-Raguler ;
}