.page-banner_cont {
    position: relative;
    width: 100%;
    height: 3rem;
    margin: .5rem 0;
}

.page-banner_cont img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.page-banner_cont .overlay{
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   background-color: rgba(0, 0, 0, 0.274);
}

.page-banner_cont .overlay .item_cont{
    display: flex;
    justify-content: space-between;
    padding: .3rem 1rem;
    width: 100%;
    height: 100%;
    align-items: center;
}
.page-banner_cont .overlay .item_cont h1{
    margin: initial;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: Lato-Medium;
}

.page-banner_cont .overlay .item_cont p{
    margin-right: 2rem;
    margin: initial;
    font-size: 1.5rem;
    font-size: var(--font_sub_heading);
}

.page-banner_cont .overlay .item_cont.absolute{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}

/* ============ Css With Floating Elem =========== */
.floating-elem  .floating_cont{
    margin: 0 auto;
    margin-bottom: 0.4rem;
    margin-top: -3%;
    position: relative !important;
    z-index: 1;
    padding: 0 1rem;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background-color: var(--secondary_color);
}

.floating_cont .btn-style{
    width: 17%;
    height: 1.8rem;
}

.floating-elem  .floating_cont table{
    margin: 0;
}

.floating-elem .page-banner_cont{
   height: 7rem !important;
}

.floating-elem .page-banner_cont .overlay .item_cont{
    height: 57%;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}