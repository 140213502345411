.field_cont{
    background-color: transparent;
    border-bottom: .2rem solid rgb(186, 186, 186);
}

.input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: .4rem 1rem;
    outline: none;
    border: none;
}

.input::placeholder{
    font-style: italic;
}