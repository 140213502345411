.search-player_cont{
    display: flex;
    align-items: center;
    background-color: var(--primary_color);
    border: .1rem solid transparent;
    transition: all .5s;
    border-radius: .2rem;
    padding: .2rem 0;
    font-size: var(--font_small);
}

.search-player_cont:hover{
    border: .1rem solid var(--green_color);
}

.search-player_cont input{
   background-color: transparent;
   outline: none;
   border: none;
   padding: 0 1rem;
   width: 20vw;
}

.search-player_cont input::placeholder{
    font-style: italic;
}

.search-player_cont .search-btn{
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    border-left: 2px solid var(--green_color);
}

.search-player_cont .search-btn i{
    color: var(--green_color);
}

/* ======== Compare Box ======= */
.compare_box_cont{
    position: fixed;
    bottom: 5rem;
    right: 5rem;
}

.compare_box_cont .compare_box_dropdown{
    border: none;
    background-color: var(--green_color);
    color: white;
    height: 2.7rem;
    width: 18rem;
    font-family:  Lato-Medium;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    border-radius: .3rem;
}

.compare_box_cont .compare_box_dropdown i{
    font-size: 1rem;
    transform: rotate(180deg);
    transition: all 200ms linear;
}

.compare_box_cont .compare_box_dropdown.open i{
    transform: rotate(0);
}

.compare_box_cont .list_of_players{
   position: relative;
   bottom: 100%;
   width: 100%;
   background-color: var(--secondary_color);
   box-shadow: 0 0 2rem rgba(0, 0, 0, 0.452);
   border-radius: .5rem .5rem 0 0 ;
   padding: 0;
   /* transform: translateY(1rem); */
   transition: all 200ms linear;
   opacity: 0;
   height: 0;
   overflow: hidden;
   pointer-events: none;
}

.compare_box_cont .list_of_players.open{
    /* transform: translateY(0); */
    opacity: 1;
    pointer-events: initial;
    /* height: 17vw; */
}

.compare_box_cont .list_of_players .list_cont{
    padding: 1.5rem;
    padding-bottom: initial;
}

.compare_box_cont .list_of_players .player_row{
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.compare_box_cont .list_of_players .player_row i{
    color: white;
    cursor: pointer;
}

.compare_box_cont .list_of_players .player_row .profile-img{
    height: 2rem !important;
    width: 2rem !important;
}

.compare_box_cont .list_of_players .player_row .player-info h4{
    font-size: 1rem;
}

.compare_box_cont .list_of_players .btn_cont{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.compare_box_cont .list_of_players .btn_cont .compare_btn{
    border-radius: 200px;
    margin: 0 auto;
    background-color: var(--green_color);
    width: 70%;
}

#players table tbody tr td:first-child{
    max-width: 21vw;
}

#players table thead tr th:first-child{
    text-align: left;
}