#groups .floating_cont {
    width: 60%;
    height: 6vw;
    padding: 0 2rem;
}

#groups .floating_cont table td,
#groups .floating_cont table th {
    font-size: 1rem;
    text-align: center;
}

#groups #groups_table_wraper {
    padding: 2rem;
}

#groups_table_wraper table td {
    font-size: 1rem;
    padding: .5rem !important;
    text-align: center;
    min-width: 12vw;
}

#groups_table_wraper table td .palceholder {
    height: .4rem;
    margin: 0 auto;
    border-radius: 100px;
    width: 3vw;
    background-color: gray;
}

#groups_table_wraper table td .add_player_btn {
    width: 10vw;
}
#groups_table_wraper table td .add_player_btn_new_to_platform {
    width: 10vw;
    background-color: gray !important; 
}


#groups_table_wraper table td:first-child {
    text-align: left;
    padding-left: 2rem !important;
    max-width: 9.6vw;
}

#groups_table_wraper table td .search_dropdown_cont {
    background-color: var(--primary_color);
}

#groups_table_wraper table td .search_dropdown_cont .dropdown-btn {
    height: 2rem;
}

#groups_table_wraper table td button {
    padding: .3rem 1rem;
}

#groups_table_wraper table td .player .profile-img {
    height: 1.9rem;
    width: 1.9rem;
}

#groups_table_wraper table td .player h4 {
    font-size: 1rem;
}

#groups_table_wraper .section-dropdown{
    background-color: var(--secondary_color);
}

.single_group_header {
    background-color: var(--green_color);
    padding: .7rem 2rem;
}

.single_group_header p:first-child {
    margin-left: initial;
}

.single_group_header p {
    margin: initial;
    margin-left: 4rem;
    font-family: Lato-bold;
}

.single_group_header .tee_time {
    background-color: white;
    color: black !important;
    padding: .3rem 2rem;
    font-family: Lato-bold;
    font-size: .9rem;
}

.single_group_header i {
    font-size: 1.2rem;
    cursor: pointer;
}

/* Edit Group Modal */

#edit_groups {
    height: fit-content;
    width: 34vw;
    padding: 2rem;
}

#edit_groups h2 {
    font-size: 1.3rem;
    font-family: Lato-raguler;
    letter-spacing: .05rem;
}

#edit_groups .filler_elem img:last-child {
    left: -14%;
    bottom: -15%;
}

#edit_groups .filler_elem img:first-child {
    right: -14%;
    top: -15%;
}

#edit_groups .form_input_cont{
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
}

#edit_groups .form_input_cont .input_field_time{
    width: 15.8vw;
}

#edit_groups .input_lable_cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

#edit_groups .input_lable_cont p{
    margin: initial;
}


 .handicap_cont{
    background-color: var(--primary_color);
    margin: 0 auto;
    padding: 0 .5rem;
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--primary_color);
    transition: all .5s;
 }
 
 .handicap_cont:hover{
   border: 2px solid var(--green_color);
 }
 .handicap_cont i{
    color: var(--gray_color-dark);
    
  }
 .handicap_cont i:hover{
    color: var(--green_color);
    transition: all .5s;
  }
 
  .handicap_cont input{
   background-color: transparent;
   border: none;
   outline: none;
   padding: .3rem;
   width: 85%;
   font-size: var(--font_small);
 }

.handicap_cont input::-webkit-outer-spin-button,
.handicap_cont input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#register_modal{
  padding: 2rem;
}

#register_modal .payment_cont{
    /* background-color: rgb(255, 170, 0); */
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    margin-top: 2rem;
}

#register_modal .payment_cont .your_wallet{
   justify-content: space-between;
   align-items: center;
   background-color: var(--secondary_color);
   padding: .5rem 1rem;
}

#register_modal  h2{
    font-size: 1.2rem;
}

#register_modal .header i{
   left: initial;
   right: 0;
   top: -0.5rem;
}