.wraper{
    background-color: var(--secondary_color);
    min-height: 74vh;
    width: 100%;
    padding: .5rem 1rem;
    color: white;
}

.wraper .heading_cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    margin-bottom:  .6rem;
}

.wraper .heading_cont h2{
    margin: initial;
    font-size: var(--font_para);
}