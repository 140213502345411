/* ============== Tournament Schedule color Start ==================== */
.tournament-schedules{
    --bs-table-accent-bg: initial !important;
    border: .3rem solid var(--secondary_color);
}

.completed-tournament{
    background-color: #495b09bc !important;
}
.major-tournament{
    background-color: #a64c1b9a !important;
}
.women-tournament{
    background-color: #ffffffee !important;
   
}

.women-tournament td{
    color: black !important;
}

.women-tournament button{
    background-color: var(--light-gray_color);
}
/* ============== Tournament Schedule color End ==================== */

.wraper .tournament-type_cont{
    display: flex;
    align-items: center;
    width: fit-content;
}

.tournament-type_cont .tournament-type{
    display: flex;
    align-items: center;
    margin-right: 1rem;
    border-right: .2rem solid var(--primary-fade_color);
    padding-right: 1rem;
}

.tournament-type_cont .tournament-type:last-child{
    margin: initial;
}

.tournament-type_cont .tournament-type p{
  margin: initial;
  margin-right: .7rem;
  font-size: var(--font_small);
  line-height: var(--font_para);
  width: 5.4rem;
}

.tournament-type_cont .tournament-type span{
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 100%;
}

/* ============= Floading container In banner ============ */
.floating_cont{
    justify-content: space-between;
    display: flex;
    height: fit-content;
    align-items: center;
    width: 41%;
    background-color: var(--secondary_color);
}

/* ======================================================= */

.tour_schedule_table{
    border-collapse:separate;
    border-spacing:0 5px;
}