.multistep-form_cont{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 68vw;
    padding: 2rem;
    background-color: var(--primary_color);
}

.multistep-form_cont .progress-and-form_cont{
   display: flex;
   height: 25vw;
   width: 90%;
   margin-top: 2rem;
} 

.progress-and-form_cont .progress_cont{
  height: 95%;
  width: 20%;
  display: flex;
  justify-content: center;
  padding-top: .5rem;
}

.progress-and-form_cont .progress_cont .progress_line{
  position: relative;
  height: 90%;
  width: .2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.progress_cont .progress_line .progress{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--green_color);
  transition: all .5s;
  height: 50%;
}

.progress_cont .progress_line .steps{
  height: 2.2rem;
  width: 2.2rem;
  background-color: white;
  color: black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  transition: all .5s;
  position: relative;
  z-index: 1;
}

.progress_line .steps.filling{
    background-color: var(--orange_color);
    color: white;
}

.progress_line .steps.completed{
   background-color: var(--green_color);
   color: white;
}

.progress-and-form_cont .form_cont{
  height: 100%;
  padding: 0 2rem;
}

.progress-and-form_cont .form_cont .input-field_cont p{
   font-size: 1.3rem;
   letter-spacing: .05rem;
}

.form_cont .input-field_cont .number-input-elem{
    width: 60%;
}

.form_cont .input-field_cont .number-input-elem img{
  height: 1rem;
}

.form_cont .input-field_cont .number-input-elem.custom-date_slector{
  width: 100%;
}

.number-input-elem.custom-date_slector .rdt{
  width: 100% !important;
}

.number-input-elem.custom-date_slector .rdtPicker {
  background-color: var(--primary_color);
  color: white;
  border: .1rem solid var(--green_color);
  border-radius: 1rem;
}

.number-input-elem.custom-date_slector .form-control{
  padding: initial;
  outline: none !important;
}

.number-input-elem.custom-date_slector i:last-child{
  color: var(  --primary-fade_color);
}

input[type="time"]::-webkit-calendar-picker-indicator{
  background-color: white;
  color: white !important;
  border-radius: 100%;
}