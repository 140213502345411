nav{
    width: 100%;
    background-color: var(--secondary_color);
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 20px 2px #00000026;
    padding: 0 5vw;
    z-index: 999;
}

nav .nav_cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav .nav_cont .logo_cont{
    height: 4.5rem;
    width: 5rem;
}

.nav_cont .logo_cont img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.nav_cont .link_cont {
    display: flex;
    align-items: center;
}

.nav_cont .link_cont button{
    margin-left: 1rem;
}

.nav_cont .link_cont .links{
    list-style: none;
    display: flex;
    margin: initial;
    padding: initial;
    margin-top: .5rem;

}

.link_cont .links .link{
    margin-right: 2.1rem;
    
}

.link_cont .links .link a{
    font-size: 1.02rem;
    letter-spacing: .02rem;
    padding: 0 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: Lato-light;
}

.link_cont .links .link .underline{
    height: .1rem;
    background-color: var(--orange_color);
    width: 0%;
    border-radius: 100px;
    transition: all .5s;
    margin-top: .1rem;
    opacity: .4;
}

.link_cont .links .link a.active .underline{
    width: 100%;
    opacity: 1;
}

.link_cont .links .link:hover .underline{
    width: 100%;
}

.nav_cont .link_cont .profile-img_cont{
   height: 2.5rem;
   width: 2.5rem;
   background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjdZ-WV_NoDh7OwLFEGLGUUtmdsGW0pjt84jqWa4DDw7c3KA1YxenVaPMxOmlnb44iq8Y&usqp=CAU");
   border-radius: 100%;
   overflow: hidden;
   background-size: cover;
   background-position: center;
   cursor: pointer;
}

nav .profile_dropdown{
    position: relative;
}

nav .dropdown_list{
    position: absolute;
    background-color: var(--primary-fade_color);
    width: 12vw;
    right: 0;
    top: 3.8vw;
    border-radius: .4rem;
    display: block;
    box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4);
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: all 200ms linear;
}

nav .dropdown_list.show{
    opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

nav .dropdown_list .triangle{
    position: absolute;
    border: .9rem solid rgba(255, 255, 255, 0);
    border-bottom-color:  var( --primary-fade_color);
    top: -1.5rem;
    right: .06rem;
}

nav .dropdown_list .options{
  padding: .8rem 0;  
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: .1rem solid rgb(91, 91, 91);
  cursor: pointer;
  transition: all .3s;
}

nav .dropdown_list .options:hover{
    background-color: var(--secondary_color);
}

nav .dropdown_list .options:last-child:hover{
    background-color: initial;
}

nav .dropdown_list .options:last-child{
    border-bottom: initial;
}

nav .dropdown_list .options p{
    margin: 0;
}

.error_msg{
    color: var(--orange_color);
    text-align: center;
    margin-top: .5rem;
    font-size: var(--font_small);
}