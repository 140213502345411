.search_dropdown_cont{
  height: 2.1vw;
  width: 16vw;
  background-color: var(--secondary_color);
  border-radius: var(--border_radius);
  font-family: 'Roboto', sans-serif;
  display: flex;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: center;
  height: fit-content;
  font-size: 1.1rem;
}

.search_dropdown_cont .dropdown-btn{
  height: 2.5rem;
}

.search_dropdown_cont .dropdown-btn span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 11vw;
}

.search_dropdown_cont .section-dropdown{
  width: 130%;
}

.search_dropdown_cont .section-dropdown.open-top{
  bottom: 3vw;
  top: initial;
}

.search_dropdown_cont .section-dropdown.open-top:after{
  bottom: -7px;
  top: initial;
  transform: rotate(180deg);
}

.search_dropdown_cont .search_cont{
   background-color: var(--primary_color);
   margin: 0 auto;
   padding: 0 .5rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border: 2px solid var(--primary_color);
   transition: all .5s;
}

.search_dropdown_cont .search_cont:hover{
  border: 2px solid var(--green_color);
}

.search_dropdown_cont .search_cont input{
  background-color: transparent;
  border: none;
  outline: none;
  padding: .3rem;
  width: 85%;
  font-size: var(--font_small);
}

.search_dropdown_cont .search_cont i{
  border-left: 2px solid var(--green_color);
  padding: .2rem;
  padding-left: .6rem ;
  font-size: var(--font_small);
  width: 15%;
  color: var(--green_color);
  cursor: pointer;
}

.search_dropdown_cont .items {
  padding: .3rem .5rem; 
  margin-top: .5rem;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--font_small);
  transition: all .5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary_color);
}

.search_dropdown_cont .items_with_players{
  padding: .3rem .8rem;;
}

.search_dropdown_cont .items_with_players .player-info{
   text-align: left;
}

.search_dropdown_cont .items:hover{
  background-color: var(--primary_color);
}

.search_dropdown_cont  .link_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--green_color);
  margin-top: .5rem;
  padding: 0 .5rem;
}

.search_dropdown_cont .link_header p{
  margin-bottom: .3rem;
  font-size: var(--font_small) !important;
}

.dropdownPlaceholder{
  font-size: .8rem !important;
  color: rgb(119, 119, 119) !important;
}