.InputFieldV2_cont input, .InputFieldV2_cont textarea{
   background-color: var( --primary-fade_color);
   color: white;
   padding: .2rem 1rem;
   border: none;
   font-size: .9rem;
   height: 100%;
   outline: none;
   border: .1rem solid var(--primary-fade_color);
   transition: all .5s;
}

.InputFieldV2_cont textarea{
    transition: initial;
    padding: 1.5rem 2rem;
    height: 100%;
}

.InputFieldV2_cont input:hover{
    border: .1rem solid var( --green_color);
}

.InputFieldV2_cont textarea:hover{
    border: .1rem solid var( --green_color);
}