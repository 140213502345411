:root {
    --primary_color: #020F17;
    --secondary_color: #16232B;
    --primary-fade_color: #253037;
    --orange_color: #E26824;
    --orange_color-dark: #a44918;
    --green_color: #7E9D10;
    --green_color-dark: #5b710b;
    --light-gray_color: #D9D9D9;

    --black_color: #000000;
    --white_color: #FFFFFF;
    --gray_color-dark: #a3a1a1;

    --font_heading: 1.5rem;
    --font_sub_heading: 1.2rem;
    --font_para: 1rem;
    --font_small: .8rem;

    --border_radius: .2rem
}

@font-face {
    font-family: Lato-raguler;
    src: url(./assets/fonts/Inter-Regular.ttf);
}

@font-face {
    font-family: Lato-bold;
    src: url(./assets//fonts/Inter-Bold.ttf);
}
@font-face {
    font-family: Lato-light;
    src: url(./assets//fonts/Inter-Light.ttf);
}
@font-face {
    font-family: Lato-Medium;
    src: url(./assets//fonts/Inter-Medium.ttf);
}

/* Smaller devices such as mobile phones */
@media (max-width: 600px) {
    /* Adjust font size for smaller devices */
    html {
      font-size: 0.875rem;
    }
  
  }
  
  /* Tablets and larger smartphones */
  @media (max-width: 950px) {
    html {
      font-size: 0.938rem !important;
    }
  
  }
  
  /* Small laptops and larger tablets */
  @media (max-width: 1024px) {
    html {
      font-size: 1vw !important;
    }
  
  }
