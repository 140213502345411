.register_player_form_cont{
    display: flex;
    align-items: center;
    height: 30vw;
    width: 100%;
    /* background-color: blueviolet; */
}

.register_player_form_cont .form_cont{
    /* background-color: aqua; */
    width: 50%;
    height: 100%;
    padding-right: 2rem;
    border-right: .2rem solid var(--green_color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register_player_form_cont .form_cont .form_input_cont{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.register_player_form_cont .form_cont .form_input_cont:last-child{
    margin: initial;
}

.register_player_form_cont .form_cont .form_input_cont p{
    margin: initial;
    width: 30%;
    font-family: Lato-raguler;
}

.register_player_form_cont .payment_cont{
    /* background-color: rgb(255, 170, 0); */
    width: 50%;
    height: 100%;
    padding: 0 2rem;
}

.register_player_form_cont .payment_cont .your_wallet{
   justify-content: space-between;
   align-items: center;
   background-color: var(--primary_color);
   padding: .5rem 1rem;
}

.payment_cont .your_wallet .wallet_value_cont{
    align-items: center;
}

.payment_cont .your_wallet p{
    margin-bottom: initial;
    font-family: Lato-Bold;
}

.payment_cont .your_wallet p span{
    color: rgb(137, 137, 137) !important;
}

.payment_cont .your_wallet .wallet_value_cont .img_cont{
    height: 2rem;
    width: 2rem;
    background-color: var(--primary-fade_color);
    padding: .2rem;
    border-radius: 100%;
    margin-right: .6rem;
}

.wallet_value_cont .img_cont img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.payment_cont .registration_fee_cont{
    margin-top: 2rem;
}

.payment_cont .registration_fee_cont .btn_and_inp_cont{
    margin-top: 2rem;
}

.payment_cont .message{
    font-size: var(--font_small) ;
    text-align: center;
    color: var(--orange_color) !important;
    margin-top: 2rem;
}