.modal_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: rgb(0 0 0 / 76%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow-y: auto;
}

.modal_cont .item_cont {
    display: flex;
    height: fit-content;
    width: fit-content;
    background-color: var(--primary_color);
}