.landing_cont {
    position: relative;
    height: 90vh;
}

.landing_cont img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.landing_cont .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.637);
}

.landing_cont .overlay .txt_cont{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing_cont .overlay .txt_cont .line-and-txt{
    display: flex;
    align-items: center;
}

.overlay .txt_cont .line-and-txt .line{
   width: 10vw;
   height: .8px;
   background-color: white;
}

.overlay .txt_cont .line-and-txt span{
    margin: 0 1.5rem;
}

.landing_cont .overlay .txt_cont p{
    text-align: center;
    width: 50%;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-family: Lato-light;
    margin-bottom: 2rem;
}

.landing_cont .overlay .txt_cont h1{
    font-weight: 100;
    font-size: 5rem;
    font-family: Lato-light;
}

.landing_cont .overlay .txt_cont .btn_cont button{
    height: 3.5vw;
    width: 14vw;
    padding: .5rem;
    margin: 0 1rem;
    text-transform: uppercase;
    letter-spacing: .06rem;
    font-size: .8rem;
}