* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Lato-light;
}

body,
html {
    width: 100%;
    background-color: var(--primary_color);
    font-size: 1.03vw !important;
}

*::-webkit-scrollbar {
    width: .5rem;
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #505050;
}

#root {
    min-height: 100vh;
    width: 100%;
    padding: 4.6vw 5vw 0;
    background-color: var(--primary_color);
}

#page-wrapers {
    min-height: 79.6vh;
}

/* #page-wrapers *{
    color: rgb(238, 238, 238);
} */

/* ============ Color Class ========= */
.orange_color {
    background-color: var(--orange_color);
}

.green_color {
    background-color: var(--green_color);
    color: var(--green_color) !important;
}

/* ================================== */

a {
    text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
input {
    color: white !important;
}

h2 {
    font-size: var(--font_heading);
    font-family: Lato-bold;
}

p {
    letter-spacing: .02rem;
}

button {
    cursor: pointer !important;
}

.btn_link {
    height: fit-content;
}



/* =========== Button Style =================== */

.btn-style {
    padding: .3rem 1rem;
    background-color: white;
    color: black;
    font-size: .9rem;
    height: fit-content;
    transition: all .5s;
    border-radius: var(--border_radius);
    border: none;
    text-align: center;
}

.btn-gray {
    background-color: var(--primary_color);
    color: white;
    transition: all .3s;
}

.btn-gray:hover {
    opacity: .7;
}

.btn-green {
    background-color: var(--green_color-dark);
    color: white;
}

.btn-orange {
    background-color: var(--orange_color);
    color: white;
}

.btn-green:hover {
    background-color: #445409;
    color: white;
}

.btn-orange:hover {
    background-color: var(--orange_color-dark);
    color: white;
}

.modal-open {
    overflow: hidden;
    width: calc(100% - 1rem); /* Adjust the width to account for the scrollbar */
}

/* ================================================= */

/* ============= Common Table Changes =============== */

table {
    border-collapse: separate !important;
    border-spacing: 0 1px;
    
}
.scroll-table {
    overflow-y: auto ;
    height: 57vw ;
} 
.scroll-table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--secondary_color);
  }

.table-striped>tbody>tr:nth-of-type(odd)>* {
    color: white;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: var(--primary-fade_color);
    --bs-table-accent-bg: var(--primary-fade_color);
    border-color: transparent;
    border-style: none;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-style: initial;
    color: white !important;
}

tbody tr {
    border-style: hidden;
    border-bottom: transparent;
}

thead tr {
    border-bottom: .3rem solid var(--green_color) !important;
    font-size: var(--font_small);
}

thead tr th {
    font-weight: 500;
}


thead tr th , tbody tr td{
    max-width: 12vw;
}

tbody tr td .badge_cont{
    margin: 0 auto;
}

tbody {
    font-size: var(--font_small);
}

.table>:not(caption)>*>* {
    padding: .3rem 1rem !important;
    vertical-align: middle;
    text-align: center;
}

.table>:not(caption)>*>* .btn-style {
    width: 8rem;
    padding: .1rem;
    font-size: .8rem;
}

/* ==================================================== */

/* ================= Ranking Badge ==================== */

.badge_cont {
    position: relative;
    width: 2vw;
    font-size: var(--font_para);
    font-weight: 600;
}

.badge_cont img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.badge_cont .ranking {
    position: absolute;
    top: 35%;
    font-family: Lato-raguler;
    font-size: .8rem;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* ===================================================== */

/* =============== Modal Css Start ================== */
.modal_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.582);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}

/* ================================================= */

/* =============== Modal Css ===================== */

.modal-content {
    background-color: var(--secondary_color) !important;
}

.modal-body,
.modal-footer {
    padding: 1.5rem !important;
}

/* =============================================== */

/* =============== Date Picker css ===================== */

.custom-date_slector .form-control {
    background-color: var(--primary_color);
    color: white;
    border: none;
    font-size: .8rem !important;
}

.custom-date_slector table{
   border-spacing: initial !important ;
}

.rdt.rdtOpenTop .rdtPicker {
    top: auto;
    bottom: 34px;
}

.custom-date_slector .form-control:focus {
    background-color: var(--primary_color);
    color: white;
    border: none;
}

.custom-date_slector .rdtPicker {
    display: none;
    position: absolute;
    width: 344px;
    padding: .4rem;
    margin-top: .7rem;
    z-index: 99999 !important;
    background: var(--primary_color);
    box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .1);
    border: none;
}

.custom-date_slector .rdtPicker td {
    height: 2.5em;
    background-color: var(--secondary_color);
    border: .2rem solid var(--primary_color);
    border-radius: .3rem;
}

.custom-date_slector .rdtPicker tr {
    border: .2rem solid var(--primary_color);
}

.custom-date_slector .rdtPicker td.rdtDay:hover,
.custom-date_slector .rdtPicker td.rdtHour:hover,
.custom-date_slector .rdtPicker td.rdtMinute:hover,
.custom-date_slector .rdtPicker td.rdtSecond:hover,
.custom-date_slector .rdtPicker .rdtTimeToggle:hover {
    background-color: var(--green_color);
    color: white;
}

.custom-date_slector .rdtPicker td.rdtActive,
.custom-date_slector .rdtPicker td.rdtActive:hover {
    background-color: var(--green_color);
    color: white;
}

.custom-date_slector .rdtPicker td.rdtToday:before {
    border-bottom: 7px solid var(--orange_color);
}

.custom-date_slector .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: var(--orange_color);
}

.custom-date_slector .rdtPicker td.rdtDisabled {
    background-color: #020F17;
    color: rgb(143, 143, 143) !important;
}

.custom-date_slector .rdtPicker td.rdtDisabled:hover {
    background-color: transparent;
}

.custom-date_slector .rdtPicker th.rdtSwitch {
    width: fit-content;
    font-size: 1rem;
    border-radius: .5rem;
}

.rdtPicker thead tr:first-of-type th{
    border: none !important;
}

.custom-date_slector .rdtPicker th.rdtSwitch:hover {
    background-color: var(--secondary_color);
}

.custom-date_slector .rdtPicker th.rdtPrev:hover,
.custom-date_slector .rdtPicker th.rdtNext:hover {
    background-color: transparent;
}

.custom-date_slector .rdtPicker th.rdtPrev span,
.custom-date_slector .rdtPicker th.rdtNext span {
    background-color: var(--secondary_color);
    border-radius: 100%;
    height: 30px;
    width: 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-date_slector thead tr:last-child {
    border-bottom: 2px solid white;
}

.custom-date_slector {
    width: 50%;
    margin-right: 1rem;
}

/* ===================================================== */

/* ============= Pagination Css Start =================== */

 .pagination-data {
    padding: 0;
    margin: 0;
}

.pagination-data li {
    list-style: none;
}

.table-filter-info {
    padding: 15px;
}

.thead-primary tr th {
    background-color: #5a8dee;
    border-color: #5a8dee;
    color: #fff;
}

.rc-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    margin-right: 8px;
}

.rc-pagination-total-text {
    margin-right: 12px;
    cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
    display: inline-block;
    min-width: 28px;
    height: 28px;
    color: rgba(0, 0, 0, .85);
    font-family: Arial;
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all .3s;
}

.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #666;
}

.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
    display: block;
    content: "•••";
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
    min-width: initial;
    height: auto;
    line-height: initial;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
    padding: 6px 8px;
    height: auto;
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: #dde0e7 !important;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
}

.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
    background-color: var(--orange_color);
    border-color: var(--orange_color);
    color: #ffffff !important;
}

.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
    background-color: #eceff5;
    border-color: #eceff5;
} 


/* ============= Pagination Css End =================== */

/* ============= Form Input Field Css ================= */
.input-field_cont .input-elem {
    display: flex;
    padding: 0 .5rem;
    width: 100%;
    align-items: center;
    border-bottom: .1rem solid var(--light-gray_color);
    margin-bottom: 2rem;
}

.input-field_cont .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 1.9rem;
}

.input-field_cont .form-check p {
    margin: initial;
}

.input-field_cont .form-check input {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: .5rem;
    background-color: var(--primary-fade_color) !important;
}

.input-field_cont .form-check-input:checked {
    background-color: var(--green_color) !important;
}

.input-field_cont .number-input-elem {
    display: flex;
    padding: .3rem .5rem;
    width: 100%;
    align-items: center;
    border: .1rem solid var(--primary-fade_color);
    margin-bottom: 1rem;
}

.input-field_cont i,
.input-field_cont img {
    margin-right: .8rem;
    color: var(--orange_color);
}

.input-field_cont input {
    background-color: transparent !important;
    border: none;
    width: 100%;
    outline: none;
}

.input_field_cont .input_field {
    padding: .2rem 1rem;
    background-color: var(--primary-fade_color);
    color: white;
    border: none;
    outline: none;
}

/* ============= Form Input Field End ================= */

/* ============== Manage Section Start====================== */

.side_tab_page_cont {
    width: 100%;
}

.manage_sec_side_bar_wraper {
    width: 19.3%;
    height: 100%;
}

.manage_sec_page_cont {
    padding: 1rem;
    width: 80%;
    /* height: 100%; */
    background-color: var(--secondary_color);
}

.manage_sec_page_cont h2 {
    font-size: var(--font_sub_heading);
    margin: initial;
}

.manage_sec_page_cont .header {
    margin-bottom: 1rem;
}

/* ============== Manage Section End====================== */

/*================= Common Form Css Start ===================== */

.form_common_style {
    position: relative;
}

.form_common_style .filler_elem img {
    position: absolute;
    height: 9rem;
}

.form_common_style .header {
    position: relative;
    width: 100%;
}

.form_common_style .header h2 {
    text-align: center;
}

.form_common_style .header i {
    position: absolute;
    left: 3vw;
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    top: .5rem;
}

.form_common_style .filler_elem img:first-child {
    right: 0;
    top: 0;
}

.form_common_style .filler_elem img:last-child {
    left: 0;
    bottom: 0;
}

.form_common_style .btn_cont {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;
    padding: 1rem;
}

.form_common_style .btn_cont button {
    margin-left: 1rem;
}

/*================= Common Form Css End ===================== */

/* =============== Loader Modal Css Start ================= */

.loader_modal{
    position: relative;
    height: 25vw;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader_modal .loader_gif_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    width: 10vw;
    border-radius: 100%;
    background-color: var(--secondary_color);
    overflow: hidden;
    margin-bottom: 2rem;
}

.loader_modal .loader_gif_cont img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.loader_modal h2{
    font-size: 1.3rem;
}

.loader_modal p{
    margin: initial;
}

.loader_modal i{
    position: absolute;
    color: white;
    cursor: pointer;
    top: 1.5rem;
    right: 2rem;
    font-size: 1.4rem;
}

.filler_dots_cont img{
    position: absolute;
    opacity: .6;
}

.filler_dots_cont img.dots_top{
    top: 0;
    left: 0;
}

.filler_dots_cont img.dots_btm{
    bottom: 0;
    right: 0;
}

/* =============== Loader Modal Css End =================== */