#create-league {
  width: 52vw;
  padding: 2rem;
}

#create-league .filler_elem img {
  width: 7vw;
}

#create-league h2 {
  font-size: 1.5rem;
}

#create-league .filler_elem img:first-child {
  top: -10%;
  right: -5%;
}

#create-league .filler_elem img:last-child {
  bottom: -10%;
  left: -5%;
}

#create-league .form_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 85%;
  margin: 0 auto;
  margin-top: 3rem;
}

#create-league .input_field_cont .input_field {
  height: 1.9rem;
  font-size: .9rem !important;
  border: 1px solid var(--primary-fade_color);
  padding: .3rem 1rem;
  width: 18vw;
  background-color: var(--primary-fade_color);
}

#create-league .search_dropdown_cont .dropdown-btn {
  height: 1.9rem;
  border: 1px solid var(--primary-fade_color);
  background-color: var(--primary-fade_color);
}

#create-league .input_field_cont .input_field:hover {
  border: 1px solid var(--green_color);
}

#create-league .search_dropdown_cont .dropdown-btn:hover {
  border: 1px solid var(--green_color);
}

#create-league .custom-date_slector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-fade_color);
  width: 18vw;
  margin-right: initial !important;
  height: 1.9rem;
}

#create-league .search_dropdown_cont{
  width: 18vw;
}

#create-league .custom-date_slector i {
  color: white;
  padding: 0 .5rem;
}

#create-league .number-input-elem.custom-date_slector .form-control {
  background-color: var(--primary-fade_color);
  border-radius: initial;
  outline: initial;
  font-size: .8rem !important;
  padding: .2rem 1rem;
  height: 1.9rem;

}

#create-league .button_cont {
  height: 5vw;
  width: 100%;
}

#create-league .btn_cont button{
  width: 8vw;
}

#create-league .input_lable_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 74%;
  margin-bottom: 1.5rem;
}

#create-league .input_lable_cont:last-child {
  margin-bottom: initial;
  align-items: flex-start;
}

#create-league .input_lable_cont p {
  margin: initial;
}

#create-league textarea.input_field {
  height: 10vw !important;
}

.form_cont .input-field_cont .number-input-elem {
  width: 60%;
}

.form_cont .input-field_cont .number-input-elem.custom-date_slector {
  width: 100%;
}

.number-input-elem.custom-date_slector .rdt {
  width: 100% !important;
}

.number-input-elem.custom-date_slector .rdtPicker {
  background-color: var(--primary_color);
  color: white;
  border: 1px solid var(--green_color);
  border-radius: 1rem;
}

.number-input-elem.custom-date_slector .form-control {
  padding: initial;
  outline: none !important;
}

.number-input-elem.custom-date_slector i:last-child {
  color: var(--primary-fade_color);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-color: white;
  color: white !important;
  border-radius: 100%;
}
/*  create tournament css */
/* 
.multistep-form_cont {
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 68vw;

  background-color: var(--primary_color);
}

.multistep-form_cont .filler_elem img {
  position: absolute;
}

.multistep-form_cont .header {
  position: relative;
  width: 100%;
}

.multistep-form_cont .header h2 {
  text-align: center;
}

.multistep-form_cont .header i {
  position: absolute;
  left: 3vw;
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
  top: .5rem;
}

.multistep-form_cont .filler_elem img:first-child {
  right: 0;
  top: 0;
}

.multistep-form_cont .filler_elem img:last-child {
  left: 0;
  bottom: 0;
}

.multistep-form_cont .progress-and-form_cont {
  display: flex;
  height: 25vw;
  width: 90%;
  margin-top: 2rem;
}

.progress-and-form_cont .progress_cont {
  height: 95%;
  width: 20%;
  display: flex;
  justify-content: center;
  padding-top: .5rem;
}

.progress-and-form_cont .progress_cont .progress_line {
  position: relative;
  height: 90%;
  width: 3px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.progress_cont .progress_line .progress {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--green_color);
  transition: all .5s;
  height: 50%;
}

.progress_cont .progress_line .steps {
  height: 35px;
  width: 35px;
  background-color: white;
  color: black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9rem;
  transition: all .5s;
  position: relative;
  z-index: 1;
}

.progress_line .steps.filling {
  background-color: var(--orange_color);
  color: white;
}

.progress_line .steps.completed {
  background-color: var(--green_color);
  color: white;
}

.progress-and-form_cont .form_cont {
  height: 100%;
  padding: 0 2rem;
}


.form_cont .input-field_cont .number-input-elem {
  width: 60%;
}

.multistep-form_cont .btn_cont {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2rem;
}

.multistep-form_cont .btn_cont button {
  margin-left: 1rem;
}

.form_cont .input-field_cont .number-input-elem.custom-date_slector {
  width: 100%;
}

.number-input-elem.custom-date_slector .rdt {
  width: 100% !important;
}

.number-input-elem.custom-date_slector .rdtPicker {
  background-color: var(--primary_color);
  color: white;
  border: 1px solid var(--green_color);
  border-radius: 1rem;
}

.number-input-elem.custom-date_slector .form-control {
  padding: initial;
  outline: none !important;
}

.number-input-elem.custom-date_slector i:last-child {
  color: var(--primary-fade_color);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-color: white;
  color: white !important;
  border-radius: 100%;
} */