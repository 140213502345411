.login_cont{
    display: flex;
    height: 70vh;
    width: 55vw;
    background-color: var(--primary_color);
}

.login_cont .lft{
    position: relative;
    height: 100%;
    width: 43%;
}

.login_cont .lft img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.login_cont .lft .overlay{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.509);
    position: absolute;
    top: 0;
    left: 0;
}

.login_cont .lft .overlay .txt_cont{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lft .overlay .txt_cont h2{
    font-size: 2rem;
}

.lft .overlay .txt_cont p{
    font-size: .9rem;
}

.lft .overlay .txt_cont button{
    width: 40%;
}

.login_cont .rgt{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 57%;
    overflow: hidden;
}


.login_cont .rgt .close{
    position: absolute;
    top: 1.2rem;
    font-size: 1.3rem;
    right: 2rem;
    cursor: pointer;
}

.login_cont .rgt .dots-pair{
    position: absolute;
    height: 6vw;
}

.login_cont .rgt .dots-pair1{
    top: -5%;
    left: 0;
}
.login_cont .rgt .dots-pair2{
    bottom: -5%;
    right: 0;
}

.login_cont .rgt h2{
    font-size: 2rem;
    margin-bottom: 2rem;
}

.login_cont .rgt .input-field_cont{
    width: 63%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login_cont .rgt .input-field_cont .input-elem{
   display: flex;
   padding: 0 .5rem;
   width: 100%;
   align-items: center;
   border-bottom: 1.5px solid var(--light-gray_color);
   margin-bottom: 2rem;
}

.login_cont .rgt .input-field_cont i{
    margin-right: .8rem;
    color: var(--orange_color);
}

.login_cont .rgt .input-field_cont input{
    background-color: transparent !important;
    border: none;
    width: 100%;
    outline: none;
}