.select_league_cont{
    display: flex;
    height: 70vh;
    width: 55vw;

    background-color: var(--primary_color);
}
/* ==========================lft============================ */

.select_league_cont .lft{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 80%;
    width: 50%;
    overflow: hidden;
}

.select_league_cont .lft .dots-pair{
    position: absolute;
    height: 6vw;
}

.select_league_cont .lft .dots-pair1{
    top: -5%;
    left: 0;
}


.select_league_cont .lft h5{
    padding-top: 3rem;
    
}

.select_league_cont .lft .leagues{
    max-height: 500px;
    width: 80%;
    overflow: auto;
    
}

.select_league_cont .lft .leagues::-webkit-scrollbar-thumb{
background-color: var(--green_color);
}
.select_league_cont .lft .leagues::-webkit-scrollbar{
    width: .3rem;
    }
.select_league_cont .lft .leagues .league-detail{
    display: flex;
    padding: 1rem 1.2rem; 
    margin-top: 1rem;
    margin-left:2rem;
    height: 2.5rem;
    font-size:var(--font_para);
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary_color);
}

.select_league_cont  .lft .leagues .league-detail p{
margin: initial;
}
.select_league_cont .lft .input-field_cont{
    width: 63%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.select_league_cont .lft .input-field_cont .input-elem{
   display: flex;
   padding: 0 .5rem;
   width: 100%;
   align-items: center;
   border-bottom: 1.5px solid var(--light-gray_color);
   margin-bottom: 2rem;
}

.select_league_cont .lft .input-field_cont i{
    margin-right: .8rem;
    color: var(--orange_color);
}

.select_league_cont .lft .input-field_cont input{
    background-color: transparent !important;
    border: none;
    width: 100%;
    outline: none;
}
/* ==========================cen============================ */

.select_league_cont .cen{
    position: relative;
    display: flex;
    background-color: var(--green_color);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 80%;
    width: 2px;
    margin: auto;
    overflow: hidden;
}


/* ==========================rgt============================ */



.select_league_cont .rgt{
    padding-top: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    
    height: 100%;
    width: 46%;

    overflow: hidden;
}


.select_league_cont .rgt .close{
    position: absolute;
    top: 1.2rem;
    font-size: 1.3rem;
    right: 2rem;
    cursor: pointer;
}

.select_league_cont .rgt .upr{
    display: flex;
    justify-content:center;
    text-align: center;
    flex-direction: column;
    width:80%;
    padding-bottom: 1rem;
    
}
.select_league_cont .rgt .upr .dropdown-container{
    margin: 1rem auto;
    width:fit-content;
}
.select_league_cont .rgt .upr .btn-container{
    width:80%;
    margin: 1rem auto;
}


.select_league_cont .rgt .middel{

    background-color: var(--primary-fade_color);
    align-items: center;
    justify-content: center;
    height: 2px;
    width: 80%;
    margin: 1rem auto;
    
}

.select_league_cont .rgt .lwr{
    display: flex;
    flex-direction: column;
    max-height: 500px;
    width:80%;
    align-items: center;
    text-align: center;
}

.select_league_cont .rgt .lwr .heading{
    width:60%;
    margin-bottom: 1rem;
}

.select_league_cont .rgt .lwr .btn-container{
    width:80%;
    
}

.select_league_cont .lft .leagues{
    
    width: 80%;
    overflow: auto;
    
}

.select_league_cont .rgt .dots-pair{
    position: absolute;
    height: 6vw;
}

.select_league_cont .rgt .dots-pair2{
    bottom: -5%;
    right: 0;
}

.select_league_cont .rgt h2{
    font-size: 2rem;
    margin-bottom: 2rem;
}

.select_league_cont .rgt .input-field_cont{
    width: 63%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.select_league_cont .rgt .input-field_cont .input-elem{
   display: flex;
   padding: 0 .5rem;
   width: 100%;
   align-items: center;
   border-bottom: 1.5px solid var(--light-gray_color);
   margin-bottom: 2rem;
}

.select_league_cont .rgt .input-field_cont i{
    margin-right: .8rem;
    color: var(--orange_color);
}

.select_league_cont .rgt .input-field_cont input{
    background-color: transparent !important;
    border: none;
    width: 100%;
    outline: none;
}