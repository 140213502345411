#score-sheet .header{
    width: 100%;
    background-color: var(--secondary_color);
    height: fit-content;
    padding: .7rem 0;
    margin: .8rem 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

#score-sheet .wraper_pading{
    padding: 1rem 0 !important;
}

.header_table{
    padding: 0 0rem;
}

#score-sheet .floating_doted_img img{
  position: absolute;
  width: 6rem;
}

#score-sheet .floating_doted_img img.img1{
    bottom: -5.3rem;
    left: 0;
}

#score-sheet .floating_doted_img img.img2{
    top: -5.3rem;
    right: 0;
}

#score-sheet .content{
   display: flex;
   align-items: center;
   width: fit-content;
   height: fit-content;
}

#score-sheet .content .text_cont{
    padding: .2rem 1rem;
    border-right: 3px solid var(--orange_color);
    height: fit-content;
    min-width: 20vw;
    text-align: center;
}

#score-sheet .content .text_cont:last-child{
    border: none;
}

#score-sheet .content .text_cont h2{
    font-size: var(--font_sub_heading);
    margin: initial;
}

#score-sheet .player_score_cont{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0rem 1.5rem .8rem; */
}

#score-sheet .player_score_cont:nth-child(odd){
    background-color: var( --primary-fade_color);
}

#score-sheet .player_score_cont .player_profile_cont{
    width: 13%;
}

#score-sheet .player_score_cont table{
    width: 86.5%;
}

.td_width{
   width: 3vw;
}

.player_table_heading{
    width: 17.5%;
}

#score-sheet table .heading{
    width: 23vw;
}

#score-sheet table{
    border-collapse:separate;
    border-spacing:0 1px;
}

#score-sheet td, #score-sheet th{
  border-bottom: 3px solid var(--green_color);
  font-size: var(--font_para);
  padding: .5rem;
}

#score-sheet th{
    font-family: Lato-bold;
}

#score-sheet .player_profile_cont .player .player-info h4{
  font-size: var(--font_para);
}

#score-sheet .player_profile_cont .player .profile-img{
    height: 2rem;
    width: 2rem;
}