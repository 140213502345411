 .seasons-dropdown_cont{
    height: 2.2vw;
    min-width: 14vw;
    background-color: var(--green_color);
    border-radius: var(--border_radius);
}

.seasons-dropdown_cont{
	font-family: 'Roboto', sans-serif;
  display: flex;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: center;
  height: fit-content;
  font-size: 1.1rem;
}
 .sec-center {
  position: relative;
  width: 100%;
  z-index: 200;
}

.seasons-dropdown_cont .dropdown-btn{
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  width: 100%;
  padding: .2rem .5rem;
  padding-left: 1rem;
  font-size: var(--font_para);
}

.seasons-dropdown_cont .dropdown-btn span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 11vw;
  font-size: .9rem;
}

.seasons-dropdown_cont .dropdown-btn .uil {
  margin-left: 30px;
  font-size: .8rem;
  transition: all 200ms linear;
}
.seasons-dropdown_cont.open .uil {
  transform: rotate(180deg);
  margin-left: 30px;
  transition: transform 200ms linear;
}
.section-dropdown {
  position: absolute;
  padding: .5rem;
  background-color: var( --primary-fade_color);
  top: 3vw;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}

.section-dropdown  .link_cont{
    max-height: 13vw;
    overflow: auto;
}

.section-dropdown .link_cont .options{
  width: 100%;
  font-size: var(--font_small);
}

.seasons-dropdown_cont.open .section-dropdown{
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-bottom: 8px solid var(--primary-fade_color);
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center .section-dropdown:after {
  border-bottom: 8px solid #fff;
}

.seasons-dropdown_cont .options {
    padding: .3rem 1rem;
  color: #fff;
 
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
    -ms-flex-pack: distribute;
    transition: all .5s;
    border-radius: var(--border_radius) ;

    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
}

.seasons-dropdown_cont .options:hover{
    background-color: var(--secondary_color);

}

@media screen and (max-width: 991px) {
.logo {
	top: 30px;
	left: 20px;
}
.dark-light:checked + .dropdown-btn,
.dark-light:not(:checked) + .dropdown-btn{
  top: 20px;
  right: 20px;
}
}