.wallet_input_cont{
    height: 5rem;
    border-radius: .3rem;
    background-color: var(--primary_color);
    color: white;
    border: .1rem solid var(--green_color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem .3rem;
}

.wallet_input_cont.border_red{
    border-color:  var(--orange_color) !important;
}

.wallet_input_cont .inp_cont{
    width: 50%;
}

.wallet_input_cont .inp_cont input{
   background-color: transparent;
   outline: none;
   border: none ;
   width: 100%;
   font-size: 1.5rem;
   font-family: Lato-bold;
}

.wallet_input_cont .inp_cont input::placeholder{
    font-size: 1rem;
}

.wallet_input_cont .inp_cont .label{
    font-size: .8rem;
    color: rgb(138, 138, 138);
}

.wallet_input_cont .coin_logo_cont{
    margin-right: 1rem;
    height: 3.5rem;
    width: 3.5rem;
    background-color: var(--primary-fade_color);
    padding: .3rem;
    border-radius: 100%;
}

.wallet_input_cont .coin_logo_cont img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}