.player_card_cont{
    width: 100%;
    /* background-color: brown; */
}

.player_card_cont .player_card{
    width: 22vw;
    padding: 1rem;
    background-color: var( --secondary_color);
    margin-right: .7rem;
    position: relative;
}

.player_card_cont .player_card .vs{
    position: absolute;
    top: 50%;
    right: -2.9vw;
    transform: translate(-50%, 0);
    height: 2.5vw;
    width: 2.5vw;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--orange_color);
    z-index: 1;
    
}

.player_card_cont .player_card .vs span{
    font-size: .9rem;
    font-family: Lato-bold;
}

.player_card_cont .player_card:last-child .vs{
    display: none;
}

.player_card_cont .player_card .add_circle{
   height: 9rem;
   width: 9rem;
   border-radius: 100%;
   margin: 0 auto;
   border: .2rem dashed gray;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 5rem;
   color: #808080;
   margin-bottom: 2rem;
}

.player_card_cont .player_card:last-child{
    margin: initial;
}

.player_card_cont .search_dropdown_cont{
    background-color: var(--primary_color);
    width: 100%;
}

.player_card_cont .search_dropdown_cont .section-dropdown{
    width: 100%;
}

.player_card_cont .add_card h2{
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1rem;
}

.player_card_cont .player_card .top .profile{
   height: 4.5vw;
   width: 4.5vw;
   border-radius: 100%;
   overflow: hidden;
   margin-right: 1.2rem;
}

.player_card_cont .player_card .player_name h2{
    font-size: 1rem;
}

.player_card_cont .player_card .player_name h2 span{
    font-size: .8rem;
}

.player_card_cont .player_card .player_name p{
    margin: initial;
    font-size: .8rem;
}

.player_card_cont .player_card .top .profile img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.player_card_cont .player_card .contact_info{
    margin-top: 1.5rem;
}

.player_card_cont .player_card .contact_info .header{
   height: .1rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.player_card .contact_info .header .line{
    height: 100%;
    width: 35%;
    background-color: rgb(136, 136, 136) !important;
}

.player_card .contact_info .header p{
    color: rgb(136, 136, 136) !important;
    font-family: Lato-Medium;
    margin: initial;
    font-size: .7rem;
}

.player_card .contact_info .info:last-child{
    margin-top: .5rem;
}

.player_card .contact_info .info{
    margin-top: 2rem;
}

.player_card .contact_info .info p{
   margin: initial;
   font-size: .9rem;
}

.player_card .contact_info .info .key{
    width: 24%;
    margin-right: 1%;
}

.player_card .contact_info .info .value{
    color: var(--green_color) !important;
}

.player_card .stats p{
    font-size: .9rem !important;
}

.player_card .stats p:first-child{
    font-size: .8rem !important;
}