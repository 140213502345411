.profile_top_sec_wraper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    background-color: var(--secondary_color);
    /* background-color: rgb(0, 145, 255); */
    margin-top: 1rem;
}

.profile_top_sec_wraper .profile_img{
    height: 16vw;
    width: 16vw;
    border-radius: .5rem;
    padding: .7rem;
    background-color: var(--primary-fade_color);
}

.profile_top_sec_wraper .profile_img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: .5rem;
    margin: 0 auto;
}

.progress_cont{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 1rem;
   margin-bottom: 1rem;
}

.progress_cont .progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin: 0  auto;
  }

.progress_cont h2{
    font-size: var(--font_para);
    font-family: Lato-raguler;
    letter-spacing: .05rem;
    margin-top: 1rem;
  }

  .profile_top_sec_wraper .btn_cont button{
    font-size: .8rem;
    margin-right: 1rem;
  }

  .profile_top_sec_wraper .btn_cont button:last-child{
    margin: initial;
  }

  .profile_top_sec_wraper .user_name_and_quat_cont h1{
     font-size: 2rem;
     font-family:  Lato-bold;
     letter-spacing: .05rem;
  }

  .profile_top_sec_wraper .user_name_and_quat_cont h1 span{
    font-size: 1.3rem;
    font-family: Lato-Medium;
    letter-spacing: .08rem;
  }

  .profile_top_sec_wraper .user_name_and_quat_cont .quats{
    margin-top: 2rem;
  }

  .info_cont{
    width: 30%;
  }

  .info_cont .info_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: .1rem;
    width: 100%;
  }

  .info_cont .info_header .line{
    height: 100%;
    background-color: gray;
    border-radius: 10rem;
    width: 100%;
  }

  .info_cont .info_header p{
    font-size: var(--font_small);
    color: gray !important;
    margin: initial;
    margin: 0 1rem;
    min-width: 7rem;
    text-align: center;
    font-family: Lato-Medium;
  }

  .info_cont .info_field{
    display: flex;
    width: 100%;
    margin-top: 2rem;
  }

  .info_cont .info_field .key{
    width: 35%;
    margin-bottom: initial;
    margin-right: 6%;
    font-family: Lato-Medium;
  }

  .info_cont .info_field .value{
    color: var(--green_color) !important;
    width: 49%;
    margin-bottom: initial;
  }

  .info_cont .info_field p span{
    color: gray !important;
  }

  .profile_top_sec_wraper .rgt h2{
     font-size: 1.1rem;
     margin: 0;
  }

  .profile_top_sec_wraper .rgt .seasons-dropdown_cont{
    background-color: var(--orange_color) !important;
  }

  .profile_top_sec_wraper .rgt .seasons-dropdown_cont .dropdown-btn{
    padding: .2rem 1rem !important;
  }

  .stats_cont .stats{
    background-color: var(--primary_color);
    align-items: center;
    padding: .7rem 1rem;
    margin-top: .4rem;
  }

  .stats_cont .stats:first-child{
    margin-top: 2rem;
  }

  .stats_cont .stats p{
    margin: 0;
  }

  .stats_cont .stats p:first-child{
    font-family: Lato-Medium;
    width: 50%;
    margin-right: 2%;
  }

  .stats_cont .stats p:last-child{
    color: var(--green_color) !important;
  }

  
  /* =================== Update Profile Modal ====================== */
  .update_profile_modal{
    height: 40vw;
    width: 75vw;
    padding: 1.6rem 5rem;
    position: relative;
    overflow: hidden;
  }

  .update_profile_modal .orange_dots img{
    height: 8vw;
  }

  .update_profile_modal .dots_top{
    top: -4.7rem;
    right: -2.5rem;
  }
  .update_profile_modal .dots_btm{
    bottom: -4.7rem;
    left: -2.5rem;
  }

  .update_profile_modal .orange_dots img{
    position: absolute;
  }

  .update_profile_modal .header{
    height: 6%;
  }

  .update_profile_modal .header h2{
     font-size: 1.5rem;
     text-align: left;
  }

  .update_profile_modal .form_cont{
    padding-top: 1rem;
    height: 85%;
    overflow-y: auto;
    padding-right: 1rem;
  }

  .update_profile_modal .form_cont::-webkit-scrollbar{
    width: .3rem;
  }

  .update_profile_modal .btn_cont{
    height: 9%;
    margin-top: initial;
    padding: 0;
    padding-top: 1rem;
    padding-right: 1rem;
  }

  .update_top_sec .update_profile_image{
      height: 9vw;
      width: 9vw;
      background-color: var(--primary-fade_color);
      border-radius: .5rem;
      padding: .6rem;
      position: relative;
      cursor: pointer;
  }
  
  .update_top_sec .update_profile_image .buttons_cont input{
    display: none;
  }

  .update_top_sec .update_profile_image .buttons_cont label{
    position: absolute;
    cursor: pointer;
    color: white;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9rem;
    border-radius: 100%;
   
  }

  .update_top_sec .update_profile_image .buttons_cont label.edit{
    right: -2.4rem;
    top: 1.5rem;
    background-color: var(--green_color);
  }

  .update_top_sec .update_profile_image .buttons_cont label.delete{
    right: -2.4rem;
    bottom: 1.5rem;
    background-color: var(--orange_color);
  }

  .update_top_sec .update_profile_image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: .6rem;
  }

  .update_btm_sec .info_cont{
    width: 45%;
  }

  .update_btm_sec .info_cont .info_field{
    justify-content: space-between;
  }

  .update_btm_sec .info_cont .info_field .key{
    margin: initial;
    width: 35%;
  }
  .update_btm_sec .info_cont .info_field .value{
    width: 51%;
  }


  .form_cont .input-field_cont .number-input-elem.custom-date_slector {
    width: 100%;
  }
  
  .number-input-elem.custom-date_slector .rdt {
    width: 100% !important;
  }
  
  .number-input-elem.custom-date_slector .rdtPicker {
    background-color: var(--primary_color);
    color: white;
    border: 1px solid var(--green_color);
    border-radius: 1rem;
  }
  
  .number-input-elem.custom-date_slector .form-control {
    padding: initial;
    outline: none !important;
  }
  
  .number-input-elem.custom-date_slector i:last-child {
    color: var(--primary-fade_color);
  }
  
  #update-player .custom-date_slector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-fade_color);
    width: 18vw;
    margin-right: initial !important;
    height: 1.9rem;
  }
  #update-player .custom-date_slector i {
    color: white;
    padding: 0 .5rem;
  }

  #update-player .number-input-elem.custom-date_slector .form-control {
    background-color: var(--primary-fade_color);
    border-radius: initial;
    outline: initial;
    font-size: .8rem !important;
    padding: .2rem 1rem;
    height: 1.9rem;
  
  }