table.Sponsors{
    border-collapse:separate;
    border-spacing:0 15px;
}

.Sponsors tbody td{
    min-width: 14.6vw;
    background-color: var( --primary_color);
    padding: 1rem;
}

.Sponsors tbody td.rgt_btn_cont{
    min-width: 9vw;
}

.Sponsors tbody td p{
    font-size: var(--font_para);
}

.Sponsors .sponsor_img_cont{
   display: flex;
   align-items: center;
}

.Sponsors .sponsor_img{
    height: 6vw;
    width: 8vw;
    border-radius: 1rem;
    background-color: white;
}

.Sponsors .sponsor_img img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.Sponsors .sponsor_img_cont .btn_cont {
    height: 5vw;
    margin-left: .5rem;
    align-items: center;
    justify-content: space-between;
}

.Sponsors .green-btn{
    background-color: var(--green_color);
}
.Sponsors .orange-btn{
    background-color: var(--orange_color);
}

.Sponsors .gray-btn{
    background-color: var(--secondary_color);
}


.Sponsors .btn_cont .button{
   color: white;
   height: 30px;
   width: 30px;
   border-radius: 100%;
   border: none;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   font-size: .8rem;
   margin-left: 1rem;
}

.Sponsors .btn_cont .button:first-child{
    margin: initial;
}

.Sponsors .sponsor_img_cont .btn_cont input{
    display: none;
}

.Sponsors .sponsor_img_cont .btn_cont .button{
    margin: initial;
}

.Sponsors tbody td h3{
    font-family:  Lato-bold;
    font-size: var(--font_heading);
}

.Sponsors tbody td .edit_input_field{
    background-color: var(--secondary_color);
    border: none;
    padding: .3rem 1rem;
    font-size: var(--font_para);
    width: 12.76vw;
    color: white;
}

.rgt_btns{
    border-left: 2px solid var(--green_color);
    padding: 1.5rem 0;
    padding-left: 1rem;
}