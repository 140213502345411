footer{
    display: flex;
    justify-content: center;
    padding: .5rem;
    border-top: 1px solid white;
    margin-top: 1.5rem;
}

footer p{
    font-size: .8rem;
    color: white;
}