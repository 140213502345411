#hga-wallet{
    width: 100%;
    background-color: var(--secondary_color);
    overflow: hidden;
}
#hga-wallet .cont{
    display: flex;
    justify-content: space-between;
}

#hga-wallet .cont .lft{
    position: relative;
    padding: 2rem;
    width: 27%;
}

#hga-wallet .dots-pair{
    position: absolute;
    height: 9vw;
}

#hga-wallet .dots-pair1{
    top: -4vw;
    left: -1vw;
}
#hga-wallet .dots-pair2{
    bottom: -4vw;
    right: 2vw;
}

#hga-wallet .cont .lft .wallet_wraper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 436px;
    max-height: 436px;
}

#hga-wallet .cont .lft .total-balance_cont{
    display: flex;
    /* width: fit-content; */
    border-radius: .5rem;
    background-color: var(--primary_color);
    padding: .6rem 1.5rem;
    margin-bottom: 1rem;
}

#hga-wallet .cont .lft .btn-style{
    height: 2.5rem;
    font-size: 1rem;
    margin-top: 3rem;
}

.lft .total-balance_cont .coin-logo{
    background-color: var(--secondary_color);
    height: 5.5vw;
    width: 5.5vw;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    padding: .5rem;
}

.lft .total-balance_cont .coin-logo img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.lft .total-balance_cont p{
    margin-bottom: .5rem;
    color: var(--gray_color-dark);
}

.lft .total-balance_cont h2{
    font-size: 2.5rem;
    font-family: Lato-light;
}

#hga-wallet .cont .rgt{
    width: 73%;
    padding: 1rem 0;
}

.tabs_cont{
    padding: 0 1rem;
    border-left: .2rem solid var( --green_color);
}

#hga-wallet .cont .rgt .top-tab_cony{
    display: flex;
    align-items: center;
    border-bottom: .2rem solid var(--green_color);
    padding-left: 2rem;
}

.cont .rgt .top-tab_cony h2{
    font-size: .8rem;
    cursor: pointer;
    margin-right: .5rem;
    letter-spacing: .05rem;
    border: 1px solid transparent;
    font-family: Lato-raguler;
    padding: .7rem .8rem;
    padding-bottom: .5rem;
    margin-bottom: -.2rem;
}

.cont .rgt .top-tab_cony h2.active{
    /* border-bottom: .2rem solid var(--orange_color); */
    background-color: #303D45;
    border: .2rem solid var(--green_color);
    border-bottom-color: #303D45;
}

/* ==================== Filter Style =================== */

.cont .rgt .filter_cont{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 1.1rem 0;
}

.filter_cont .btn-style{
   width: 7vw;
}

.filter_cont .btn-style:last-child{
    margin-left: 1rem;
}

.cont .rgt .filter_cont form{
    width: 84%;
    align-items: flex-end;
}

.filter_cont form label{
    color: var(--gray_color-dark) !important;
    margin-bottom: .5rem;
 }

 .filter_cont form .date-input{
     background-color: var(--primary_color);
     border: none;
     color: white;
     width: 90%;
 }
 
/* ===================================================== */

.purchese_coin_cont{
    overflow: hidden;
    height: 30vw;
}

.purchese_coin_cont .dots_btm{
    right: -2.5rem !important;
    bottom: -4rem !important;
    height: 9rem;
}

.purchese_coin_cont .dots_top{
    left: -2.5rem !important;
    top: -4rem !important;
    height: 9rem;
}

.wallet_input_cont{
    width: 55%;
    margin: 0 auto;
    margin-top: 3rem;
}

.wallet_input_cont .divider_line{
    height: .1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
}

.wallet_input_cont .divider_line .line{
    background-color: white;
    height: 100%;
    width: 43%;
    border-radius: 100px;
}

.purchese_coin_cont .header{
    position: relative;
}

.purchese_coin_cont .header .close_btn{
   position: absolute !important;
   width: fit-content;
   top: -1rem;
   right: 2rem;
   left: initial;
   font-size: 1.2rem;
}

.history_tab_cont{
    background-color: #303D45;
    padding: 1rem;
}

.history_tab_cont tbody tr{
    background-color: var(--secondary_color) !important;
}

.history_tab_cont table{
    border-collapse: separate;
    border-spacing: 0 .5rem;
}

.date_selector p{
   font-size: .8rem;
   /* color: gray !important; */
   margin: initial;
}

.date_selector .custom-date_slector {
    background-color: var(--primary_color);
    align-items: center;
    width: 18vw;
    padding: .1rem 1rem;
}

.date_selector .custom-date_slector i:first-child{
    color: var(--orange_color);
    font-size: 1rem;
}

.date_selector .custom-date_slector i:last-child{
    color: gray;
    font-size: .8rem;
}